body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  background-color: #ABA9BC;
}

#root {
  overflow-x:hidden
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "regular";
  src: local('Marcellus-Regular'),
  url('./fonts/Marcellus-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "sourceSans";
  src: local('SourceSansPro-Regular'),
  url('./fonts/SourceSansPro-Regular.ttf') format('truetype');
}