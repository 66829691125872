.footer {
    font-family: sourceSans;
    background-color: #1C2444;
    color: #F2F3Fb;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-rows: 1fr 2fr;
    justify-items: center;
    font-size: 16px;
    gap: 1rem;
    height: 40rem;
}

.hoursContainer, .addressContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 15rem;
    padding-top: 1rem;
    padding-left: 1rem;
}

.addressContainer p {
    text-align: center;
    margin: .2rem
}

.socialContainer {
    grid-column-start: 1;
    grid-column-end: 3;
    width: 20rem;
    justify-self: center;
}

.socialContainer p {
    padding: none
}

.socialIcons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2rem;
}

.socialMediaLogo {
    width: 2rem;
}

.socialLink {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #F2F3Fb;
    border: 1px solid #F2F3Fb;
    width: 3rem;
    height: 3rem;
    border-radius: 15px;
}

.emailLink {
    display: block;
    text-decoration: none;
    color: #F2F3Fb;
    font-size: 20px;
    font-weight: 600;
}

.day {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.bold {
    font-weight: 600;
}

.day p {
    justify-content: flex-end;
    margin: 0;
    padding: 0;
}

@media only screen and (min-width: 768px) {

.footer {
    height: 25rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: 18px;
    gap: 1.5rem;
}

.hoursContainer, .socialContainer, .addressContainer {
    width: 15rem;
    height: 21rem;
}

.hoursContainer, .addressContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0;
}

.socialIcons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2rem;
}

.socialMediaLogo {
    width: 2rem;
}

.socialLink:hover {
    background-color: #767586;
    border: 1px solid #F2F3Fb;
}

.socialLink {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #F2F3Fb;
    width: 3rem;
    height: 3rem;
}

.socialLink:active {
    transform: translate(0, 5px);
}

.emailLink {
    display: block;
    font-size: 22px;
    width: 5rem;
}

.emailLink::after {
    display: block;
    content: '';
    border-bottom: 3px solid #F9F5EB;
    transform: scaleX(0); 
    transition: transform 350ms ease-in-out;
}

.emailLink:hover::after {
    transform: scaleX(1)
}

.day {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.day p {
    justify-content: flex-end;
    margin: 0;
    padding: 0;
}
}