
.homepage {
    background-color: #ffff;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}


.imageRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.seal {
    width: 4.5rem;
}

.badge {
    width: 3.5rem;
}

.plane {
    width: 12rem;
}

.planeBanner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 2rem;
    gap: 1rem;
}

.seasonalLightContainer {
    display: flex;
    flex-direction: row;
}


#leftOne {
    display:none;
}

.seasonalBold {
    font-weight: 700;
}

.seasonalBanner {
    /* background-image:url('../images/backgrounds/american-flag.jpeg'); */
    /* background-repeat:no-repeat;
    background-size:cover; */
    background-color: #a8c7fa;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 26rem;
    border-bottom: 2px solid rgb(49, 95, 195);
    box-shadow: 0px 0px 6px #1C2444;
    gap: 2rem;
}

.seasonalImageLight {
    height: 7rem;
}


/* rgb(202, 230, 202);  rgb(49, 95, 195); */
.seasonalContent {
    background-color: #fff ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    /* border: 2px solid lightblue; */
    /* box-shadow: 0px 0px 6px white; */
    line-height: 1.7;
    margin-top: 0rem;
    margin-bottom: 0rem;
    padding-bottom: 0rem;
    border: 4px solid #001F5B;
    border-radius: 10px;
}

.seasonalTitleOne {
    text-align: center;
    font-size: 15px;
    color: #001F5B;
    font-weight: 600;
    padding: .2rem;
}

.seasonalTitle {
    text-align: center;
    font-size: 20px;
    color: #001F5B;
    font-weight: 600;
    padding: .2rem;
    border-bottom: 2px solid darkred;
    padding-left: 2rem;
    padding-right: 2rem;
    
}

.seasonalText {
    text-align: center;
    font-size: 14px;
    color: #003FAA;
    font-weight: 600;
    line-height: 1.8;
}

.shopImage {
    border: 1px solid #1C2444;
    border-radius: 15px;
    width: 21rem;
    box-shadow: 0px 0px 6px #1C2444;
}

.modalPic {
    width: 25rem;
}

.firstSection, .secondSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    padding: 1rem;
}

.firstSection {
    padding-top: 1.5rem;
}

.descriptionContainer, .services, .aboutContainer, .signupContainer {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 2rem;
    width: 18rem;
    background-color: #F2F3FB;
    color: #1C2444;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    box-shadow: 1px 1px 10px 3px #1c2444;
    border-radius: 15px;
}

.aboutContainer {
    flex-direction: column;
    padding: 1rem;
}

.homepageLinkContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.homepageLink {
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    font-weight: 700;
    width: 4.7rem;
    padding: .5rem;
    background-color: #003FAA;
    color: #F2F3FB;
    border-radius: 15px;
    font-size: 18px;
    border: 2px solid #003FAA;
    box-shadow: 2px 2px 2px 3px #1c2444;
}

.homepageLink:active {
    background-color: #AC5372;
    border: 2px solid #AC5372;
    box-shadow: 2px 2px 2px 3px black;
    color: #fefefe;
    transform: translate(0, 5px);
}

.aboutLink {
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    background-color: #001F5B;
    color: #E8FCFF;
    width: 14rem;
    border-radius: 15px;
    padding: .4rem;
    margin-top: 1rem;
    box-shadow: 2px 2px 6px black;
    border: 2px solid #001F5B
}

.line {
    text-decoration: underline;
}

.descriptionContainer h2, h3{
    margin: 0;
    padding: .5rem;
}

.descriptionContainer h2, .services h3 {
    color: #003FAA
}

.services h3 {
    font-weight: 800;
}

.services ul {
    color:#001F5B;
    list-style: none;
    padding: 0;
    margin: 0
}

.services p {
    color:#001F5B;
    padding: 0px;
    margin: 0
}

.animatedDiv {
    transition-duration: .5s;
}

.storePic {
    width: 20rem;
    height: 20rem;
    border: 1px solid #001F5B;
    border-radius: 15px;
}

.signupContainer {
    width: 18rem;
}

.signupContainer form{
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    padding:1rem
}

.emailInput {
    font-size: 16px;
    font-weight: 600;
    width: 15rem;
    height: 1rem;
    border-radius: 15px;
    padding: .5rem;
    text-align: center;
    border: 2px solid #1C2444
}

.emailInput:focus {
    border: 2px solid #E8FCFF;
    background-color: #C6CCFF;
}

.submitButton {
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    background-color: #001F5B;
    color: #E8FCFF;
    width: 14rem;
    border-radius: 15px;
    padding: .4rem;
    margin-top: 1rem;
    border: 2px solid #001F5B;
    box-shadow: 2px 2px 6px black;
}

.submitButton:active, .aboutLink:active {
    background-color: #1C2444;
    color:#F2F3FB;
    transform: translate(0, 5px);
}

.error {
    display: flex;
    font-size: 16px;
    color: red;
    padding: none;
}
.noError {
    display: none;
}


.mapContainer {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

@media only screen and (min-width: 768px) {

.seal {
    width: 7rem;
}

.planeBanner {
    width: 100%;
    height: 8rem;
    gap: 15rem;
}

.badge {
    width: 7rem;
}

.plane {
    width: 22em;
}

.homepage {
    gap:1.2rem;
}

.homepageLinkContainer {
    display: none;
}

#leftOne {
    margin-top: -.6rem;
    display: block;
}

.seasonalImageLight {
    width: auto;
    height: 10rem;
}

.seasonalBanner {
    height: 18rem;
}

.seasonalContent {
    width: 85%;
    margin-top: 0rem;
    margin-bottom: 0rem;
}

.seasonalText {
    font-size: 16px;
    line-height: 1.8;
}

.seasonalTitle, .seasonalTitleOne {
    font-size: 22px;
    font-weight: 800;
}

.seasonalTitleOne {
    font-size: 20px;
}

.shopImage {
    width: 40rem;
    cursor: pointer;
}

.firstSection, .secondSection {
    flex-direction: row;
    gap: 2rem;
    padding: .2rem;
}

.firstSection {
    padding-top: 1.5rem;
}

.descriptionContainer, .services, .aboutContainer, .signupContainer {
    text-align: center;
    font-size: 20px;
    line-height: 2.5rem;
    width: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.aboutContainer {
    width: 50rem;
    flex-direction: column;
    padding: 1rem;
    height: 24rem;
}

.aboutText {
    width: 44rem;
}

.aboutLink {
    font-size: 24px;
    width: 18rem;
}

.services {
    flex-direction: column;
}

.descriptionContainer {
    flex-direction: column;
    width: 30rem;
    
}

.description {
    font-size: 17px;
}

.descriptionContainer h2, h3{
    margin: 0;
    padding: .5rem;
}

.services ul {
    color:#001F5B;
    list-style: none;
    padding: none;
    margin: 0
}

.services p {
    color:#001F5B;
    padding: none;
    margin: 0
}

.animatedDiv {
    transition-duration: 1s;
}

.storePic {
    width: 25rem;
    height: 25rem;
}

.modalPic {
    width: 100rem;
}

.signupContainer {
    width: 50rem;
    height: 15rem;
}

.signupContainer form{
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.emailInput {
    font-size: 20px;
    width: 15rem;
    height: 2rem;
    padding: .5rem;
    border: 3px solid #1C2444
}

.emailInput:focus {
    border: 3px solid #E8FCFF;
}

.submitButton {
    text-decoration: none;
    font-size: 24px;
    font-weight: 800;
    background-color: #001F5B;
    color: #E8FCFF;
    width: 18rem;
    border-radius: 15px;
    padding: .5rem;
    margin-top: 1rem;
    box-shadow: 2px 2px 6px black;
    cursor: pointer;
}

.submitButton:hover, .aboutLink:hover {
    background-color: #000023;
    color:#F2F3FB;
    border: 2px solid #F2F3FB
}

.mapContainer {
    margin-top: 1rem;
}

}

@media only screen and (min-width: 768px) and (max-width: 1170px){

    .homepage, .firstSection, .secondSection {
        gap: 0.8rem;
    }

    .seal {
        width: 7rem;
    }    

    .seasonalContent {
        width: 90%;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .seasonalTitle {
        font-size: 18px;
    }
    
    .seasonalText {
        font-size: 15px;
    }
    
    #leftOne {
        display:block;
    }

    .shopImage {
        width: 26rem;
    }

    .homepageLinkContainer {
        display: none;
    }

    .descriptionContainer, .services, .aboutContainer, .signupContainer {
        font-size: 15px;
        width: 22rem;
        padding: .5rem;
    }

    .description {
        font-size: 15px;
    }

    .aboutContainer {
        width: 35rem;
    }

    .aboutContainer h2 {
        font-size: 20px;
    }

    .aboutText {
        width: 32rem;
        font-size: 15px;
    }

    .signupContainer{
        width: 28rem;
        padding-bottom: 1rem;
    }

    .signupContainer form label {
        font-size: 18px;
    }

    .modalPic {
        width: 60rem;
    }
}