.brandPageContainer {
    background-color: #ffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-top: 2rem;
}

.brandsInfoContainer {
    text-align: center;
    width: 18rem;
    font-size: 16px;
    font-weight: 600;
    line-height: 1rem;
    background-color: #F2F3FB;
    color: #1C2444;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: .5rem;
    padding-top: 1rem;
    border-radius: 15px;
    box-shadow: 1px 1px 10px 3px #1c2444;
}

.brandsInfoContainer h3 {
    color: #003FAA;
}

.sizes {
    width: 15rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
}

.sizes p {
    margin: 0
}

.colorbold {
    font-size: 17px;
    color: #003FAA;
    font-weight: 700;
}

.brandLogoContainer {
    width: 21rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.logoCard {
    width: 6rem;
    height: 6rem;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    perspective: 1000px;
}

.logoCardInner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.logoCard:hover .logoCardInner {
    transform: rotateY(180deg);
}

.logoCardFront, .logoCardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 1px 1px 10px 3px #1c2444;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoCardFront {
    background-color: #1C2444;
    border-radius: 15px;
}

.logoCardBack {
    background-color: #F2F3FB;
    border: 2px solid #1C2444;
    color: #003FAA;
    font-size: 20px;
    font-weight: 800;
    border-radius: 15px;
    transform: rotateY(180deg);
}

.brandLogo {
    border: 4px solid #F2F3FB;
    border-radius: 15px;
    width: 5rem;
    height: 5rem;
    background-color: #FFFF;
}

.cardsize {
    width: 5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 15px;
    background-color: #ffff;
}

@media only screen and (min-width: 768px) {

.brandPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-top: 2rem;
}

.brandsInfoContainer {
    width: 30rem;
    font-size: 20px;
    line-height: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    padding-top: 2rem;
}

.sizes {
    width: 28rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
}

.colorbold {
    font-size: 22px;
    font-weight: 800;
}


.brandLogoContainer {
    width: 65rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.logoCard {
    width: 12rem;
    height: 12rem;
    padding: 1rem;
}

.logoCardBack {
    font-size: 24px;
}

.brandLogo {
    width: 10rem;
    height: 10rem;
}

.cardsize {
    width: 10rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    border-radius: 15px;
    background-color: #ffff;
}

}

@media only screen and (min-width: 768px) and (max-width: 1170px){

    .brandLogoContainer {
        width: 42rem;
    }

}