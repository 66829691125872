.mensContainer {
    background-color: #ffff;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding-top: 2rem;
}

.descriptionMensContainer {
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    line-height: 1.5rem;
    background-color: #F2F3FB;
    color: #1C2444;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-shadow: 1px 1px 10px 3px #1c2444;
    border-radius: 15px;
    width: 18rem;
}

.descriptionMensContainer h3 {
    color: #003FAA;
}

.categoryContainer {
    background-color: #F2F3FB;
    box-shadow: 1px 1px 10px 3px #1c2444;
    border-radius: 15px;
    width: 18rem;
    height: 5rem;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.categoryButton, .menscategoryButton {
    cursor: pointer;
    text-align: center;
    font-weight: 700;
    width: 7rem;
    padding: .5rem;
    background-color: #003FAA;
    color: #F2F3FB;
    border-radius: 15px;
    font-size: 18px;
    border: 2px solid #003FAA;
    box-shadow: 2px 2px 2px 3px #1c2444;
}

.activeButton, .mensactiveButton {
    cursor: pointer;
    text-align: center;
    font-weight: 700;
    width: 7rem;
    padding: .5rem;
    background-color: #AC5372;
    color: #fefefe;
    border-radius: 15px;
    font-size: 18px;
    border: 2px solid #fefefe;
    box-shadow: 2px 2px 2px 3px #1c2444;
}

.mensactiveButton, .menscategoryButton {
    cursor: pointer;
    height: 4rem;
}

.categoryButton:hover, .menscategoryButton:hover {
    border: 2px solid #F2F3FB;
    background-color: #1C2444;
}

.categoryButton:active, .menscategoryButton:active {
    background-color: #AC5372;
    color: #fefefe;
    transform: translate(0, 5px);
}

.modalOpen {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
}

.modalClosed {
    display: none;
}

.modalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:2rem;
    position: relative;
    width: 80%;
    background-color: #fefefe;
    margin: auto;
}

.modalName {
    font-size: 18px;
    padding-bottom: 1.5rem;
    font-weight: 700;
}

.modalImage {
    width: 17rem;
}

.closeIcon {
    cursor: pointer;
    color: white;
    position: absolute;
    top: 5px;
    right: 25px;
    font-size: 50px;
    font-weight: bold;
}

.prevmodalArrows, .nextmodalArrows {
    color: #F2F3FB;
    cursor: pointer;
    z-index: 100;
    top: 50%;
    width: .5rem;
    padding: .5rem;
    margin-top: -4rem;
    font-size: 40px;
    font-weight: bold;
    border-radius: 5px;
    position: absolute;
    margin-right: auto;
}

.nextmodalArrows {
    right: 2%;
    border-radius: 5px
}

.prevmodalArrows {
    left: 1%;
}

@media only screen and (min-width: 768px) {

    .mensContainer {
        gap: 3rem;
        padding-top: 2rem;
    }

    .descriptionMensContainer {
        font-size: 20px;
        line-height: 2.5rem;
        padding: 1rem;
        width: 40rem;
    }

    .categoryContainer {
        width: 30rem;
        height: 8rem;
        padding: 1rem;
        gap: 2rem;
    }

    .categoryButton, .activeButton, .menscategoryButton, .mensactiveButton {
        width: 10rem;
        padding: 1.5rem;
        font-size: 24px;
    }

    .mensactiveButton, .menscategoryButton {
        height: 6rem;
    }

    .modalOpen {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: black;
    }

    .modalContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:2rem;
        position: relative;
        background-color: #fefefe;
        margin: auto;
    }
    
    .modalName {
        font-size: 24px;
        font-weight: 700;
    }
    
    .modalImage {
        height: 100%;
        width: auto;
    }

}

@media only screen and (min-width: 768px) and (max-width: 1170px){

    .modalImage {
        height: 60%;
        width: 100%;
        object-fit:cover;
    }

    
}

