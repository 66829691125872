
.App {
  font-family: "regular";
  margin: 0 auto;
  box-shadow: 0px 0px 28px 3px rgb(0 0 0 / 53%);
}



@media only screen and (min-width: 768px) {

.App {
  font-family: "regular";
  max-width: 1260px;
  margin: 0 auto;
  box-shadow: 0px 0px 28px 3.5px rgb(0 0 0 / 53%);
}

}