
.contactContainer {
    background-color: #ffff;
    padding: 1rem;
    gap: 1.5rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contactTitle {
    color: #003FAA;
    font-size: 17px;
    font-weight: 700;
    line-height: 1.5;
}

.contactForm {
    border-radius: 15px;
    width: 18rem;
    color: #1C2444;
    background-color: #F2F3FB;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    box-shadow: 2px 2px 6px black;
    align-items: center;
}

.contactForm label {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.contactInput, .contactMessage {
    width: 7rem;
    padding: .5rem;
    border-radius: 15px;
    border: 2px solid #1C2444;
    font-size: 16px;
}

.contactInput:focus, .contactMessage:focus {
    background-color: #C6CCFF;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.contactMessage {
    resize: none;
    width: 16rem;
    height: 10rem;
}

.infoContainer {
    border-radius: 15px;
    width: 18rem;
    color: #1C2444;
    background-color: #F2F3FB;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 1rem;
    box-shadow: 2px 2px 6px black;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 700;
}

.infoContainer h2 {
    color: #003FAA
}

.email {
    text-decoration: none;
    color: #003FAA;
    font-size: 22px;
}

.address {
    text-align: center;
    width: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media only screen and (min-width: 768px) {

    .contactContainer {
        padding: 1rem;
        gap: 1rem;
        padding-bottom: 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        align-items: center;
    }
    
    .contactTitle {
        font-size: 22px;
        line-height: 2;
    }

    .contactForm {
        grid-row-start: 1;
        grid-row-end: 3;
        width: 24rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 2rem;
        align-items: center;
    }

    .contactInput, .contactMessage {
        width: 10rem;
        padding:1rem;
        font-size: 18px;
    }

    .contactMessage {
        resize: none;
        width: 20rem;
        height: 10rem;
    }

    .contactForm label {
        gap: 0
    }

    .infoContainer {
        width: 24rem;
        gap: 1rem;
        padding: 2rem;
        font-size: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1170px){

    .infoContainer {
        width: 18rem;
        font-size: 16px;
        padding: 1rem;
    }

    .contactForm {
        padding: 1rem;
        width: 20rem;
        gap: 1rem;
    }

    .contactTitle {
        line-height: 1.5;
        font-size: 18px;
    }

    .row {
        flex-direction: column;
    }

    .contactMessage {
        width: 16rem;
    }
}