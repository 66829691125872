
.photoCarouselContainer {
    background-color: #F2F3FB;
    color: #1C2444;
    width: 18rem;
    position: relative;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 10px 3px #1c2444;
    border-radius: 15px;
    padding: 1rem;
    gap: 1rem;
}

.imageContainer {
    width: 8rem;
    height: 8rem;
    background-color: #FFFF;
    box-shadow: 3px 3px 5px 0px #1C2444;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slideImage {
    width: 8rem;
    border-radius: 15px;
}

.slideTitle {
    font-size: 19px;
    font-weight: 600;
}

.dotContainer {
    width: 100%;
    position: absolute;
    display: flex;
    top: 88%;
    justify-content: center;
    z-index: 200;
}

.dot, .activeDot {
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0px 3px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    display: inline-block;
}

.activeDot, .dot:hover {
    background-color: rgba(51, 8, 243, 0.664);
}

@media only screen and (min-width: 768px) {

    .photoCarouselContainer {
        background-color: #F2F3FB;
        color: #1C2444;
        width: 50rem;
        position: relative;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        box-shadow: 1px 1px 10px 3px #1c2444;
        border-radius: 15px;
        padding: 1rem;
    }

    .imageContainer {
        width: 15rem;
        height: 15rem;
    }

     .slideImage {
        cursor: pointer;
        width: 14rem;
        border-radius: 15px;
    }

    .dotContainer {
        top: 92%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1170px){

    .photoCarouselContainer {
        width: 40rem;
    }

}