.header {
    font-size: 17px;
    height: 6rem;
    font-weight: 700;
    color: #F2F3FB;
    background-color: #1C2444;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
}

.phoneNumber {
    font-family: sourceSans;
}

.title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:1rem;
    padding-right: 3rem;
}

.titleText {
    text-decoration: none;
    color: #F2F3FB;
    font-size: 20px;
    font-weight: 700;
}

.leftLinks, .rightLinks, .sloganText {
    display: none;
}

.navContainer {
    display: flex;
    flex-direction: column;
    width: 5rem;
}

.navigation {
    width: 12rem;
    background-color: #444655;
    position:absolute;
    overflow-y: visible;
    top: 6rem;
    left: 0rem;
    
}

.navigation ul {
    display: flex;
    flex-direction: column;
    gap: .4rem;
    margin: 0;
    padding-left: 5px;
    padding-top: 5px;
}

.navigation ul li {
    list-style: none;
    border-bottom: 1px solid #F9F5EB;
    text-decoration: none;
    color: #F9F5EB;
}

.mobileLink {
    margin: 0px;
    display: flex;
    text-decoration: none;
    color: #F9F5EB;
    padding: 2px;
    width: 10rem;
    height: 2.5rem;
}

.hamburgerMenu{
    padding-bottom: 1.5rem;
}

.hamburger {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    z-index: 10;
    padding-top: 15px;
    margin-left: 10px;

}

.burger {
    width: 2rem;
    height: 0.25rem;
    border-radius: 10px;
    background-color: #F2F3FB;
    transform-origin: 1px;
    transition: all 0.3s linear;
}

@media only screen and (min-width: 768px) {

.navigation, .navContainer {
    display: none;
}

.hamburgerMenu {
    display: none;
}

.header {
    height: 12rem;
    font-size: 20px;
    font-weight: 700;
    color: #F2F3FB;
    background-color: #1C2444;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
}
.sloganText {
    display: flex;
    font-size: 18px;
}

.title {
    display: flex;
    flex-direction: column;
    flex-shrink: 2;
    justify-content: center;
    align-items: center;
    padding-right: 0;
    gap:1rem
}

.titleText {
    cursor: pointer;
    text-decoration: none;
    color: #F2F3FB;
    font-size: 38px;
    font-weight: 700;
}

.leftLinks, .rightLinks {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 2rem;
}

.headerLink {
    text-decoration: none;
    color: #F9F5EB;
    cursor: pointer;
    padding: .5rem;
}

.titleText::after {
    display: block;
    content: '';
    border-bottom: 2px solid #F9F5EB;
    transform: scaleX(0); 
    transition: transform 250ms ease-in-out;
}

.headerLink::after {
    display: block;
    content: '';
    border-bottom: 2px solid #F9F5EB;
    transform: scaleX(0); 
    transition: transform 250ms ease-in-out;
}

.headerLink:hover::after {
    transform: scaleX(1)
}

.titleText:hover::after {
    transform: scaleX(1)

}
}

@media only screen and (min-width: 768px) and (max-width: 1170px){

    .titleText{
        font-size: 28px;
    }

    .sloganText {
        display: none;
    }
}